import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VContainer,
        { attrs: { id: "interest-table", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: { inline: "", color: "secondary" },
              scopedSlots: _vm._u([
                {
                  key: "heading",
                  fn: function () {
                    return [_c(VIcon, [_vm._v("mdi-close-box-multiple")])]
                  },
                  proxy: true,
                },
                {
                  key: "after-heading",
                  fn: function () {
                    return [
                      _c("h1", { staticClass: "h5" }, [
                        _c("b", [_vm._v(_vm._s(_vm.$t("error_log")))]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              !_vm.loading
                ? _c(
                    "div",
                    [
                      _c(
                        VForm,
                        {
                          ref: "form",
                          attrs: { "lazy-validation": _vm.lazy },
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.get_logs_by_page()
                            },
                          },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            VRow,
                            {
                              staticClass: "mt-6",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(VTextField, {
                                staticClass: "mx-3",
                                staticStyle: { "max-width": "35%" },
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: _vm.$t("email"),
                                },
                                model: {
                                  value: _vm.name_filter,
                                  callback: function ($$v) {
                                    _vm.name_filter = $$v
                                  },
                                  expression: "name_filter",
                                },
                              }),
                              _c(
                                VBtn,
                                {
                                  staticClass: "mx-3 secondary",
                                  attrs: { type: "submit" },
                                },
                                [_vm._v(_vm._s(_vm.$t("apply")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(VSimpleTable, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v(_vm._s(_vm.$t("email")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("body")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("error")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("date")))]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.logs, function (l, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(l.UserEmail))]),
                              _c("td", [_vm._v(_vm._s(l.Body))]),
                              _c("td", [_vm._v(_vm._s(l.Error))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.gs.convert_date(l.Date, false))
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]),
                      _c(VPagination, {
                        attrs: { color: "secondary", length: _vm.total_pages },
                        on: { input: _vm.changePage },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    VContainer,
                    [
                      _c(VProgressCircular, {
                        staticStyle: { "margin-left": "50%" },
                        attrs: {
                          indeterminate: "",
                          size: "70",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }